import React, { useCallback, useState, useEffect } from 'react';
import { Container, Card } from 'react-bootstrap';
import Grid from '@mui/material/Grid2';
import './RestaurantCardDeck.css';
import RESTAURANTS from '../Restaurant/restaurants.json';

interface Restaurant {
  name: string;
  rating: number;
  cuisine: string;
  price: string;
  city: string;
  state?: string;
  country: string;
  image_name: string;
}

export default function RestaurantCardDeck() {
  const [cards, setCards] = useState();
  const [regions, setRegions] = useState<string[]>([]);
  const [sortedRestaurantsByRegion, setSortedCardsByRegion] = useState();
  const sanitizedRegions = Object.keys(regions).map((key: string) => key.toLowerCase().replace(/\s+/g, "-")); // remove spaces


  //regions = {"New York": 7, "California": 1}
  const countRegions = (restaurants: any) => {
    return restaurants.reduce((acc, { state }) => {
      acc[state] = (acc[state] || 0) + 1;
      return acc;
    }, {});
  }

  // {"New York": ["Cho Dang Gol","Aska"], "Washington": ["Lupo"]}
  const countRestaurantsByRegion = (restaurants: any) => {
    return restaurants.reduce((acc, { state, name }) => {
      if (!acc[state]) {
        acc[state] = [];
      }
      acc[state].push(name);
      return acc;
    }, {});
  }

  const createCardDeck = useCallback(() => {
    var cardDeck: any = {};
    var regions = countRegions(RESTAURANTS);

    RESTAURANTS.forEach((restaurant: Restaurant) => {
      cardDeck[restaurant.name] =
        (<Card key={restaurant.name} style={{ width: '14rem' }}>
          <Card.Img variant="top" src={restaurant.image_name} />
          <Card.Body>
            <Card.Title>{restaurant.name}</Card.Title>
            <ul className="list-unstyled list-inline rating mb-0">
              {renderRatingStars(restaurant.rating)}
              <li className="list-inline-item ml-1"><span className="text-muted">{restaurant.rating}</span></li>
            </ul>
            <p className="mb-2">{restaurant.price} • {restaurant.cuisine} </p>
            <Card.Text>
              {restaurant.city}, {restaurant.state === "" ? restaurant.country : restaurant.state}
            </Card.Text>
          </Card.Body>
        </Card>
        );
    })
    setRegions(regions);

    setSortedCardsByRegion({
      ...countRestaurantsByRegion(RESTAURANTS)
    });
    setCards({
      ...cardDeck
    });
  }, [])

  useEffect(() => {
    createCardDeck();
  }, [createCardDeck]);


  // retrieveRestaurants() {
  //   RestaurantDataService.getAll()
  //     .then(response => {
  //       this.setState({
  //         restaurants: response.data
  //       });
  //       this.createCardDeck(response.data);
  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  // }


  const renderRatingStars = (rating) => {
    var stars: any = [];

    for (var i = 0; i < Math.floor(rating); i++) {
      stars.push(<li key={i} className="list-inline-item mr-0"><i className="fas fa-star amber-text"></i></li>);
    }

    // check to see if decimal - add half star
    if ((rating - Math.floor(rating)) !== 0) {
      stars.push(<li key={i} className="list-inline-item mr-0"><i className="fas fa-star-half-alt amber-text"></i></li>);
      i++;
    }

    // add empty stars
    while (i < 5) {
      stars.push(<li key={i} className="list-inline-item mr-0"><i className="far fa-star amber-text"></i></li>);
      i++;
    }

    return stars;
  }

  function renderRows(region: string) {
    //key = region,  value = [ Cards ]
    var rows: any = [];

    if (sortedRestaurantsByRegion && cards) {
      // retrieve list of restaurants in specified region ex. ['Cho Dang Gol', 'Aska']
      const restaurantsInRegion: string[] = sortedRestaurantsByRegion?.[region] ?? [];

      for (const restaurant of restaurantsInRegion) {
        rows.push(
          cards[restaurant]
        )
      }
    }
    else {
      console.error("Error loading data rows.")
    }

    return rows;
  }

  function sortCardsByRegion(regions: string[]) {
    var display: any = [];

    for (const region in regions) {
      const sanitizedRegion = region.toLowerCase().replace(/\s+/g, "-"); // "New York" => "new-york"
      display.push(
        <Container key={sanitizedRegion}>
          <header id={sanitizedRegion}>
            <h4 className="text">{region.toUpperCase()}</h4>
          </header>
          <Grid container spacing={10} direction="row" sx={{ justifyContent: "center", alignItems: "flex-start" }}>
            {renderRows(region)}
          </Grid>
        </Container>);
    }

    return display;
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid size={{ xs: 4, md: 2 }}>
          <nav id="sidenavbar">
            <h5 style={{ textAlign: "start" }}>Regions</h5>
            <ul style={{ display: "inline-block", margin: 0, padding: 0 }}>
              {sanitizedRegions.map((heading) => (
                <li key={heading} style={{ cursor: "pointer", margin: 0, padding: 0, paddingBottom: "10px", textAlign: "start" }} >
                  <a
                    href={`#${heading}`}
                    onClick={(e) => {
                      e.preventDefault()
                      document.querySelector(`#${heading}`).scrollIntoView({
                        behavior: "smooth"
                      })
                    }}
                  >
                    {heading}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </Grid>
        <Grid size={{ xs: 8, md: 10 }}>
          {sortCardsByRegion(regions)}
        </Grid>
      </Grid>
    </>
  )
}