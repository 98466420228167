import * as React from 'react';
import { useState, useMemo } from 'react';
import './TravelPage.css';
import 'maplibre-gl/dist/maplibre-gl.css';
import Map, { Marker, Popup } from 'react-map-gl/maplibre';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import YouTube, { YouTubeProps } from 'react-youtube';
import {
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl
} from '@vis.gl/react-maplibre';
import CITIES from './cities.json';
import Pin from './Pin';
import ControlPanel from './ControlPanel';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';

interface CityData {
  city: string;
  video_id: string;
  state: string;
  country: string;
  latitude: number;
  longitude: number;
}

// { "New York City": 2, "Boston": 1, "Seattle": 1 }
function countCities(restaurants: CityData[]): Record<string, number> {
  return restaurants.reduce((acc, { city }) => {
    acc[city] = (acc[city] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);
}

export default function TravelPage() {
  const [popupInfo, setPopupInfo] = useState<CityData | null>(null);
  const cityCount = countCities(CITIES);

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  const pins = useMemo(
    () =>
      CITIES.map((city, index) => (
        <Marker
          key={`marker-${index}`}
          longitude={city.longitude}
          latitude={city.latitude}
          anchor="bottom"
          onClick={e => {
            e.originalEvent.stopPropagation();
            setPopupInfo(city);
          }}
        >
          {cityCount[city.city] !== 1 ?
            <Badge color="secondary" badgeContent={`${cityCount[city.city]}`} >
              <Pin />
            </Badge> :
            <Pin />
          }


        </Marker>
      )),
    [cityCount]
  );

  const youTubeOptions = {
    height: '100%',
    width: '100%',
    playerVars: {
      // autoplay: 1,
      controls: 1,
    },
  };

  return (

    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid size={12}>
          <div id='top'>
            <Map
              initialViewState={{
                longitude: -100.4,
                latitude: 37.8,
                zoom: 3,
                bearing: 0,
                pitch: 0
              }}
              style={{ width: "100%", height: "80vh" }}
              mapStyle="https://api.maptiler.com/maps/streets/style.json?key=u99QxpoUbtY8nA5zgZQX"
            >
              <GeolocateControl position="top-left" />
              <FullscreenControl position="top-left" />
              <NavigationControl position="top-left" />
              <ScaleControl />

              {pins}

              {popupInfo && (
                <Popup
                  anchor="top"
                  longitude={Number(popupInfo.longitude)}
                  latitude={Number(popupInfo.latitude)}
                  onClose={() => setPopupInfo(null)}
                >
                  <Typography variant="caption">{popupInfo.city}, {popupInfo.country !== "US" ? popupInfo.country : popupInfo.state}</Typography>
                  <YouTube videoId={popupInfo.video_id} opts={youTubeOptions} onReady={onPlayerReady} id={popupInfo.video_id} />
                </Popup>
              )}
            </Map>
            <ControlPanel />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}