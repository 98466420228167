import React, { useState } from 'react';
import './HomePage.css';
import Graph from '../../components/Graph/Graph';
import { SocialIcon } from 'react-social-icons';
import Alert from 'react-bootstrap/Alert';

function AlertDismissible() {
    const [show, setShow] = useState(true);

    if (show) {
        return (
            <Alert variant="info" onClose={() => setShow(false)} dismissible>
                <Alert.Heading>↑ Try it out! </Alert.Heading>
                <p>
                     Feel free to drag around and play with the nodes above {' '}
                    <a target="_new" href={`https://d3js.org/`}> 
                        (Built with D3.js)
                    </a>
                </p>
                <hr />
            </Alert>
        );
    }
    return <br></br>;
}

export default function HomePage() {
    return (
        <div>
            <div id='top'>
                <div id='expand'>
                    <Graph name='expand' />
                </div>
                <AlertDismissible />
                <div id="profile">
                    <img className="img-fluid rounded-circle" src="https://dmjsacw2lb7bt.cloudfront.net/main/profile2.jpeg" alt="profile-pic" />
                    <p>Amanda Wang | Software Engineer | Seattle, WA</p>
                    <div className="social">
                        <SocialIcon target="_blank" rel="noopener noreferrer" url="https://www.linkedin.com/in/amandawa" />
                        <SocialIcon target="_blank" rel="noopener noreferrer" url="https://www.instagram.com/amandawangster/" />
                        <SocialIcon target="_blank" rel="noopener noreferrer" url="mailto:amandaaw23@gmail.com" />
                        <SocialIcon target="_blank" rel="noopener noreferrer" url="https://www.youtube.com/@amandawa23" />
                    </div>

                </div>
            </div>

            <div className="App-header" id="bottom">
                <div className="container">
                    <br></br>
                    <br></br>
                    <br></br>
                    <p style={{fontFamily: "Courier New"}}>
                        Hi! I'm Amanda ^-^<br></br>
                        I love to eat good food and make travel videos to capture the moments.<br></br>
                        <br></br>
                        This is a simple web app I built using React.<br></br>
                        It serves as time capsule for some of my favorite memories ~
                    </p>
                </div>
            </div>
        </div>
    )

}