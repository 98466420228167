import React, { useState, useEffect } from 'react'; import './GalleryPage.css';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ImageList from '@mui/material/ImageList';
import Skeleton from '@mui/material/Skeleton';
import { useMediaQuery, useTheme } from "@mui/material";

const url = "https://dmjsacw2lb7bt.cloudfront.net/gallery/"
const images = [
  {
    original: url + "gal-dolomites3.jpg",
    thumbnail: url + "gal-dolomites3.jpg",
    location: "Dolomites, Italy",
    description: "Beginning of Tre Cime hike",
  },
  {
    original: url + "gal-summit.jpg",
    thumbnail: url + "gal-summit.jpg",
    location: "Acadia National Park, ME",
    description: "Cadillac North Ridge trail",
  },
  {
    original: url + "gal-dolomites1.jpg",
    thumbnail: url + "gal-dolomites1.jpg",
    location: "Dolomites, Italy",
    description: "Cinque Torri",
  },
  {
    original: url + "gal-arizona.jpg",
    thumbnail: url + "gal-arizona.jpg",
    location: "Sedona, Arizona",
    description: "Bell Rock Pathway trail",
  },
  // {
  //   original: url + "yamanashi1.jpg",
  //   thumbnail: url + "yamanashi1.jpg",
  //   location: "Yamanashi Prefecture, Japan",
  //   description: "",
  // },
  {
    original: url + "gal-dolomites2.jpg",
    thumbnail: url + "gal-dolomites2.jpg",
    location: "Dolomites, Italy",
    description: "Lago di Braies",
  },
  {
    original: url + "yamanashifarm2.jpg",
    thumbnail: url + "yamanashifarm2.jpg",
    location: "Hokuto, Yamanashi Prefecture, Japan",
    description: "",
  },
  {
    original: url + "gal-rainer.JPG",
    thumbnail: url + "gal-rainer.JPG",
    location: "Mount Rainier, WA",
    description: "Lenticular cloud formation :)",
  },
  {
    original: url + "gal-rainier2.jpg",
    thumbnail: url + "gal-rainier2.jpg",
    location: "Mount Rainier, WA",
    description: "Fall foliage on the skyline loop trail",
  },
  {
    original: url + "gal-seattle.jpg",
    thumbnail: url + "gal-seattle.jpg",
    location: "Seattle, WA",
    description: "Golden Gardens Park",
  },
  {
    original: url + "gal-seattlehiking.jpg",
    thumbnail: url + "gal-seattlehiking.jpg",
    location: "Mount Rainier, WA",
    description: "Ohanapecosh Campground",
  },
  {
    original: url + "azores.jpg",
    thumbnail: url + "azores.jpg",
    location: "Azores, Portugal",
    description: "Lagoa do Fogo",
  },
  {
    original: url + "kamakura.jpeg",
    thumbnail: url + "kamakura.jpeg",
    location: "Kamakura, Japan",
    description: "",
  },
  {
    original: url + "kamakura2.jpg",
    thumbnail: url + "kamakura2.jpg",
    location: "Kamakura, Japan",
    description: "",
  },
  {
    original: url + "gal-dolomites4.jpg",
    thumbnail: url + "gal-dolomites4.jpg",
    location: "Dolomites, Italy",
    description: "Beginning of Tre Cime hike",
  },
  {
    original: url + "yamanashi2.jpg",
    thumbnail: url + "yamanashi2.jpg",
    location: "Yamanashi Prefecture, Japan",
    description: "",
  },
  {
    original: url + "yamanashifarm.jpg",
    thumbnail: url + "yamanashifarm.jpg",
    location: "Yamanashi Prefecture, Japan",
    description: "Minami Alps",
  },
  {
    original: url + "gal-CR.JPG",
    thumbnail: url + "gal-CR.JPG",
    location: "Costa Rica",
    description: "",
  },
  {
    original: url + "lakefuji.jpg",
    thumbnail: url + "lakefuji.jpg",
    location: "Lake Kawaguchi, Japan",
    description: "Mount Fuji",
  },

];

export default function GalleryPage() {
  const [loadedImages, setLoadedImages] = useState<{ [key: string]: boolean }>({});
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // sm = mobile

  useEffect(() => {
    const preloadImages = async () => {
      const promises = images.map(
        (item) =>
          new Promise<void>((resolve) => {
            const img = new Image();
            img.src = `${item.original}?w=248&fit=crop&auto=format`;
            img.srcset = `${item.original}?w=248&fit=crop&auto=format&dpr=2 2x`;
            img.onload = () => {
              setLoadedImages((prev) => ({ ...prev, [item.original]: true }));
              resolve();
            };
          })
      );
      await Promise.all(promises); // Wait until all images are loaded
      setAllImagesLoaded(true); // Update state to show images
    };

    preloadImages();
  }, []);


  return (
    <div id='top'>
      <div className="container">
        <p>Photo Gallery </p>
        <ImageList variant="masonry" cols={3} gap={8}>
          {images.map((item) => (
            allImagesLoaded ? (
              <div className="image-container" key={item.original}>
                <ImageListItem key={item.original}>
                  <img
                    srcSet={`${item.original}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.original}?w=248&fit=crop&auto=format`}
                    alt={item.original}
                  />
                  {!isMobile && (
                    <ImageListItemBar
                      className="hover-bar"
                      title={item.location}
                      subtitle={item.description}
                    />
                  )}
                </ImageListItem>
              </div>
              ) : (
              <ImageListItem key={item.original}>
                <Skeleton variant="rectangular" width={"100%"} height={200} />
                <Skeleton />
              </ImageListItem>
            )
          ))}
        </ImageList>
        <br></br>
      </div>
    </div>
  )
}