import React, { useEffect, useState } from 'react';
import './Graph.css';
import * as d3 from 'd3';
import Button from 'react-bootstrap/Button';

function getJSONFromURL() {
    return fetch("https://gist.githubusercontent.com/mbostock/74cb803c013404ac30e63f020a52a2fd/raw/c7c74c939b602c56c80848963f9ad24802baaead/graph.json")
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            console.error(error);
        });
}

interface GraphProps {
    name: string;
}

interface Node {
    id: string;
    group: string;
    radius: number;
    citing_patents_count: number;
}

interface Link {
    source: string;
    target: string;
    value: number;
}

interface GraphData {
    nodes: Node[];
    links: Link[];
}

const Graph: React.FC<GraphProps> = ({ name }) => {

    const [graphData, setGraphData] = useState<GraphData>({
        nodes: [],
        links: [],
    });

    const [screenWidth, setScreenWidth] = useState<number>(window.outerWidth);

    useEffect(() => {
        getJSONFromURL().then((graph) => {
            var svg = d3.select("#graph-" + name),
                width = +svg.attr("width"),
                height = +svg.attr("height");
            setGraphData(graph);
            init(graph, svg, width, height);
        });

        // update screenWidth on window resize
        const handleResize = () => {
            setScreenWidth(window.outerWidth)
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize); // cleanup
        };
    }, [name]);

    useEffect(() => {
        var svg = d3.select("#graph-" + name);
        svg.attr("width", screenWidth);
    }, [screenWidth, name]);


    const init = (graph, svg, width, height) => {

        var radius = 5;
        //initializes nodes in the center
        svg.attr("viewBox", [-width / 2, -height / 2, width, height]);

        const color = d3.scaleOrdinal(d3.schemeSet2);

        // var color = d3.scaleOrdinal()
        //     .range(["red", "green", "blue", "#6b486b", "#a05d56", "#d0743c", "#ff8c00"]);

        var simulation = d3.forceSimulation()
            .force("link", d3.forceLink().id(function (d) { return d.id; }))
            .force("charge", d3.forceManyBody())
            .force("y", d3.forceY());


        var link = svg.append("g")
            .attr("class", "links")
            .selectAll("line")
            .data(graph.links)
            .join("line");

        var node = svg.append("g")
            .attr("class", "nodes")
            .selectAll("circle")
            .data(graph.nodes)
            .join("circle")
            .attr("r", radius)
            .attr("fill", function (d) { return color(d.group); })
            .call(d3.drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragended));

        simulation
            .nodes(graph.nodes)
            .on("tick", ticked);

        simulation.force("link")
            .links(graph.links);

        function ticked() {
            link
                .attr("x1", function (d) { return d.source.x; })
                .attr("y1", function (d) { return d.source.y; })
                .attr("x2", function (d) { return d.target.x; })
                .attr("y2", function (d) { return d.target.y; });

            node
                .attr("transform", function (d) { return "translate(" + d.x + "," + d.y + ")"; });
        }

        function dragstarted(event, d) {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
        }

        function dragged(event, d) {
            d.fx = event.x;
            d.fy = event.y;
        }

        function dragended(event, d) {
            if (!event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
        }

    }


    const resetGraph = (explode: boolean) => {
        //this.svg.selectAll("svg > *").remove(); // removes nodes but does not reset position of nodes and do simulation
        getJSONFromURL().then((graph) => {
            d3.selectAll("svg#graph-expand > *").remove(); // clears everything within an graph svg

            var svg = d3.select("#graph-" + name),
                width = +svg.attr("width"),
                height = +svg.attr("height");

            var radius = 5;
            //initializes nodes in the center
            svg.attr("viewBox", [-width / 2, -height / 2, width, height]);

            const color = d3.scaleOrdinal(d3.schemeCategory10);

            var simulation = d3.forceSimulation()
                .force("link", d3.forceLink().id(function (d) { return d.id; }))
                .force("charge", d3.forceManyBody())
                .force("y", d3.forceY())

            var link = svg.append("g")
                .attr("class", "links")
                .selectAll("line")
                .data(graph.links)
                .join("line");

            var node = svg.append("g")
                .attr("class", "nodes")
                .selectAll("circle")
                .data(graph.nodes)
                .join("circle")
                .attr("r", radius)
                .attr("fill", function (d) { return color(d.group); })
                .call(d3.drag()
                    .on("start", dragstarted)
                    .on("drag", dragged)
                    .on("end", dragended));

            //hover over node to see id
            node.append("title")
                .text(d => d.id);

            simulation
                .nodes(graph.nodes)
                .on("tick", ticked);

            if (!explode) {
                simulation.force("link")
                    .links(graph.links);
            }

            function ticked() {
                link
                    .attr("x1", function (d) { return d.source.x; })
                    .attr("y1", function (d) { return d.source.y; })
                    .attr("x2", function (d) { return d.target.x; })
                    .attr("y2", function (d) { return d.target.y; });

                node
                    .attr("transform", function (d) { return "translate(" + d.x + "," + d.y + ")"; });
            }

            function dragstarted(event, d) {
                if (!event.active) simulation.alphaTarget(0.3).restart();
                d.fx = d.x;
                d.fy = d.y;
            }

            function dragged(event, d) {
                d.fx = event.x;
                d.fy = event.y;
            }

            function dragended(event, d) {
                if (!event.active) simulation.alphaTarget(0);
                d.fx = null;
                d.fy = null;
            }
        });
    }

    return (
        <div>
            <svg id={"graph-" + name} width={screenWidth} height="220"></svg>
            <div>
                <Button id="resetButton" onClick={() => resetGraph(false)} variant="outline-success">Reset</Button>
                <Button id="fireworksButton" onClick={() => resetGraph(true)} variant="outline-success">Explode!</Button>
            </div>
            <br />
        </div>
    )
}

export default Graph;