import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

export default class CustomNavBar extends React.Component {

    render() {
        return (
            <>
                <Navbar expand="lg">
                    <Navbar.Brand href="/">Amanda</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <ul className="navbar-nav mr-auto">
                                <li><Link to='/travel' className="nav-link"> Travel</Link></li>
                                <li><Link to='/gallery' className="nav-link"> Gallery</Link></li>
                                <li><Link to='/food' className="nav-link"> Food</Link></li>
                            </ul>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </>
        )
    }

}