import React, { useState, useEffect } from 'react';
import './App.css';
import HomePage from './pages/HomePage/HomePage';
import GalleryPage from './pages/GalleryPage/GalleryPage';
import CustomNavBar from './pages/NavBar/CustomNavBar.js';
import FoodPage from './pages/FoodPage/FoodPage';
import TravelPage from './pages/TravelPage/TravelPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import Footer from "./Footer.js";


export default function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const isFirstVisit = localStorage.getItem("hasVisitedHomePage");

    if (!isFirstVisit) {
      const timeoutId = setTimeout(() => {
        setIsLoading(false);
        localStorage.setItem("hasVisitedHomePage", "true"); // Mark as visited
      }, 1500); // Simulate loading time (1.5 seconds)
      return () => clearTimeout(timeoutId); // Cleanup function

    } else {
      setIsLoading(false); // If they've already visited, skip the spinner
    }

  }, []); // Runs only once on mount


  return (
    isLoading ? <div className="spinner"></div>
      :
      <Router>
        <div className="App">
          <CustomNavBar />
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/travel' element={<TravelPage />} />
            <Route path='/gallery' element={<GalleryPage />} />
            <Route path='/food' element={<FoodPage />} />
          </Routes>
          <Footer />
        </div>
      </Router>
  );
}
