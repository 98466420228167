import * as React from 'react';

function ControlPanel() {
  return (
    <div className="control-panel">
      <p style={{fontFamily: "Courier New"}}>
        Click on a marker to view travel vlog 🎥
      </p>
    </div>
  );
}

export default React.memo(ControlPanel);