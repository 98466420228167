import React from "react";
import {
    Box,
    FooterContainer,
    Row,
    FooterLink,
} from "./FooterStyles";

const Footer = () => {
    return (
        <Box>
            <h3
                style={{
                    color: "papayawhip",
                    textAlign: "center",
                    marginTop: "10px",
                }}
            >
                ʕ •ᴥ•ʔ っ♡ ♡
            </h3>
            <FooterContainer>
                <Row>
                    <FooterLink target="_blank" rel="noopener noreferrer" href="mailto:amandaaw23@gmail.com">
                        <i className="fa fa-envelope">
                            <span
                                style={{
                                    marginLeft: "10px",
                                }}
                            >
                                Email
                            </span>
                        </i>
                    </FooterLink>
                    <FooterLink target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/amandawangster/">
                        <i className="fab fa-instagram">
                            <span
                                style={{
                                    marginLeft: "10px",
                                }}
                            >
                                Instagram
                            </span>
                        </i>
                    </FooterLink>
                    <FooterLink target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/amandawa">
                        <i className="fab fa-linkedin">
                            <span
                                style={{
                                    marginLeft: "10px",
                                }}
                            >
                                LinkedIn
                            </span>
                        </i>
                    </FooterLink>
                    <FooterLink target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@amandawa23">
                        <i className="fab fa-youtube">
                            <span
                                style={{
                                    marginLeft: "10px",
                                }}
                            >
                                YouTube
                            </span>
                        </i>
                    </FooterLink>
                </Row>
            </FooterContainer>
            <p
                style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "10px"
                }}
            >
                Last updated: 03/06/2025
            </p>
        </Box>
    );
};
export default Footer;