import React from 'react';
import './FoodPage.css';
import RestaurantCardDeck from '../../components/CardDeck/RestaurantCardDeck';


export default function FoodPage() {
    return (
        <div className="container" >
            <RestaurantCardDeck />
            <br></br>
        </div>
    )
}